// import {  } from "country-flag-icons/react/3x2";
// types
import type { ReactNode } from "react";
import type { Country } from "../../types/misc";

/* eslint-disable @stylistic/jsx-pascal-case */
const FLAGS = {} as const satisfies Partial<Record<Country, ReactNode>>;
/* eslint-enable @stylistic/jsx-pascal-case */

export default FLAGS;
